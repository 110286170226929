<template>
  <div>
    <v-text-field
      v-model="searchProp"
      append-icon="mdi-cloud-search-outline"
      :label="$t('search')"
      :placeholder="$t('searchterm')"
      solo
      style="max-width: 500px"
    ></v-text-field>

    <v-data-table
      class="elevation-2 mitfit-table"
      :loading="preloader"
      loading-text="Loading... Please wait"
      :headers="headers"
      :items="items"
      :items-per-page="10"
      :search="searchProp"
      :edit="edit"
      :trash="trash"
      :copy="copy"
      :leads="leads"
      :preview="preview"
      :item-class="row_classes"
      :footer-props="{
        'items-per-page-text': $t('itemsPerPage'),
      }"
    >
      <template v-slot:[`item.planTitle`]="{ item }">
        <strong>{{ item.startYear }}/{{ nextYear(item.startYear) }}</strong>
      </template>

      <template v-slot:[`item.sellingState`]="{ item }">
        <v-chip
          v-if="item.invoice[0].lastStatus === 'paid'"
          class="ma-2"
          color="green"
          dark
          >{{ $t("Bought") }}</v-chip
        >
        <v-chip v-else class="ma-2" color="red" dark>{{ $t("Open") }}</v-chip>
      </template>

      <template v-slot:[`item.pay.paymentStatus`]="{ item }">
        <v-chip
          v-if="item.pay.paymentStatus === 'completed'"
          class="ma-2"
          color="green"
          dark
        >
          {{ item.paymentMethod.toUpperCase() }}
        </v-chip>
        <v-chip
          v-else
          class="ma-2"
          :color="`${
            item.underageParticipant === '1' ? 'orange' : 'green lighten-2'
          }`"
          dark
        >
          {{ $t("invoice") }}
        </v-chip>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          v-if="item.status === 'completed'"
          class="ma-2"
          color="green"
          dark
          >{{ $t("Complete") }}</v-chip
        >
        <v-chip
          v-else
          class="ma-2"
          :color="`${
            item.underageParticipant === '1' ? 'orange' : 'green lighten-2'
          }`"
          dark
          >{{ $t("noPayment") }}</v-chip
        >
      </template>

      <template v-slot:[`item.memberContractName`]="{ item }">
        <span
          v-if="
            item.order && item.order.included && item.order.included.items[0]
          "
        >
          {{ item.order.included.items[0].title }}
        </span>
      </template>

      <template v-slot:[`item.pay.document`]="{ item }">
        <span v-if="item.pay.document">
          <v-btn
            @click="downloadDocument(item.pay.document)"
            class="mx-2"
            fab
            dark
            small
            elevation="0"
            color="purple"
            :loading="generatingDocument"
          >
            <v-icon dark>mdi-file-document</v-icon>
          </v-btn>
        </span>
      </template>

      <template v-slot:[`item.sellingContractState`]="{ item }">
        <v-chip
          v-if="item.items[0] && item.items[0].fileLink"
          class="ma-2"
          color="green"
          dark
          >{{ $t("Bought") }}</v-chip
        >
        <v-chip v-else class="ma-2" color="red" dark>{{ $t("Open") }}</v-chip>
      </template>

      <template v-slot:[`item.buyerInvoice`]="{ item }">
        <v-btn
          :href="item.invoice[0].link"
          target="_blank"
          v-if="item.invoice[0].link"
          class="mx-2"
          fab
          dark
          small
          elevation="0"
          color="purple"
        >
          <v-icon dark>mdi-file-document</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.buyerContract`]="{ item }">
        <v-btn
          v-if="item.items[0] && item.items[0].fileLink"
          :href="item.items[0].fileLink"
          target="_blank"
          class="mx-2"
          fab
          dark
          small
          elevation="0"
          color="purple"
        >
          <v-icon dark>mdi-file-document</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.cmpStatus`]="{ item }">
        <v-icon
          v-if="Math.floor(Date.now() / 1000) < item.page_startdate"
          color="grey"
          >mdi-clock-time-ten</v-icon
        >
        <v-icon
          v-else-if="Math.floor(Date.now() / 1000) > item.page_enddate"
          color="grey lighten-2"
          >mdi-check-bold</v-icon
        >
        <v-icon v-else color="green">mdi-lightning-bolt</v-icon>
      </template>

      <template v-slot:[`item.videoLibraryThumbnail`]="{ item }">
        <v-avatar v-if="item.item.vimeo_thumbnails[0]">
          <img :src="item.item.vimeo_thumbnails[0].link" alt="Thumbnail" />
        </v-avatar>

        <v-avatar v-else color="primary">
          <v-icon dark>mdi-account-circle</v-icon>
        </v-avatar>
      </template>

      <template v-slot:[`item.videoLibraryPrivacy`]="{ item }">
        <span v-if="item.item.vimeo_privacy === 'anybody'">
          {{ $t("Anybody") }}
        </span>

        <span v-else-if="item.item.vimeo_privacy === 'password'">
          {{ $t("AnybodyWithPassword") }}
        </span>

        <span v-else>
          {{ $t("Nobody") }}
        </span>
      </template>

      <template v-slot:[`item.isDeleted`]="{ item }">
        <span v-if="item.isDeleted === '0'"> </span>

        <span v-else-if="item.isDeleted === '1'">
          {{ $t("deleted") }}
        </span>
      </template>

      <template v-slot:[`item.pay.isTerminated`]="{ item }">
        <span v-if="item.pay.isTerminated === '0'"> </span>

        <span v-else-if="item.pay.isTerminated === '1'">
          {{ $t("Terminated") }}
        </span>
      </template>

      <template v-slot:[`item.videoLibraryState`]="{ item }">
        <span v-if="item.item.vimeo_status === 'FINISHED'">
          <span v-if="item.item.vimeo_privacy === 'disable'">
            <v-icon color="gray">mdi-eye-off-outline</v-icon>
          </span>
          <span v-else-if="item.item.vimeo_privacy === 'password'">
            <v-icon color="red">mdi-lock</v-icon>
          </span>
          <span v-else>
            <v-icon color="green">mdi-eye-outline</v-icon>
          </span>
        </span>

        <span v-else-if="item.item.vimeo_status === 'UPDATE'">
          <v-btn :loading="true" small color="white" elevation="0" fab>
            <v-icon dark>mdi-cloud-upload</v-icon>
          </v-btn>
        </span>

        <span
          v-else-if="
            item.item.vimeo_status === 'NONE' ||
            item.item.vimeo_status === 'TRANSCODING' ||
            item.item.vimeo_status === 'DELETE' ||
            item.item.vimeo_status === 'THUMBNAIL'
          "
        >
          <v-btn :loading="true" small color="white" elevation="0" fab>
            <v-icon dark>mdi-cloud-upload</v-icon>
          </v-btn>
        </span>

        <span v-else>
          {{ item.item.vimeo_status }}
        </span>
      </template>

      <template v-slot:[`item.videoLibraryTimestamp`]="{ item }">
        {{ timestampToDate(item.item.created) }}
      </template>

      <template v-slot:[`item.created`]="{ item }">
        <span v-if="item.created && item.created !== '0000-00-00 00:00:00'">{{
          formatDate(item.created)
        }}</span>
      </template>

      <template v-slot:[`item.person_registerdate`]="{ item }">
        <span
          v-if="
            item.person_registerdate &&
            item.person_registerdate !== '0000-00-00 00:00:00'
          "
          >{{ formatDate(item.person_registerdate) }}</span
        >
      </template>

      <template
        v-slot:[`item.campaigns[0].games_data_participationdate`]="{ item }"
      >
        <span
          v-if="
            item.campaigns[0].games_data_participationdate &&
            item.campaigns[0].games_data_participationdate !==
              '0000-00-00 00:00:00'
          "
          >{{
            formatDate(item.campaigns[0].games_data_participationdate)
          }}</span
        >
      </template>

      <template v-slot:[`item.updated`]="{ item }">
        <span v-if="item.updated">{{ formatDate(item.updated) }}</span>
      </template>

      <template v-slot:[`item.adSource`]="{ item }">
        <span v-if="item.adSource && item.adSource !== 'null'">{{
          item.adSource
        }}</span>
        <span v-else>-</span>
      </template>

      <template v-slot:[`item.underageParticipant`]="{ item }">
        <span
          v-if="
            item.underageParticipant &&
            item.underageParticipant !== 0 &&
            item.underageParticipant !== '0'
          "
        >
          <v-icon large color="orange darken-3"> mdi-human-male-child </v-icon>

          <span style="display: none">{{ item.underageParticipant }}</span>
        </span>
        <span v-else></span>
      </template>

      <template v-slot:[`item.actionIcon`]="{ item }">
        <span v-html="item.actionIcon"></span>
      </template>

      <template v-slot:[`item.trafficLightIcon`]="{ item }">
        <!-- Inklusive Leads von manage.mitfit -->
        <v-icon
          v-if="
            item.person_client === 'Ja' ||
            item.person_client === '1' ||
            item.identityIsClient === '1'
          "
          large
          color="green"
          >mdi-trophy-variant</v-icon
        >
        <v-icon
          v-else-if="item.games_data_gamer_lost === 'ja' || item.isLost === '1'"
          large
          color="grey darken-3"
          >mdi-checkbox-blank-circle</v-icon
        >
        <v-icon
          v-else-if="
            item.games_data_gamer_begun === 'ja' || item.hasRedeemed === '1'
          "
          large
          color="light-green"
          >mdi-checkbox-blank-circle</v-icon
        >
        <v-icon
          v-else-if="
            item.games_data_gamer_scheduled === 'ja' || item.isScheduled === '1'
          "
          large
          color="lime"
          >mdi-checkbox-blank-circle</v-icon
        >
        <v-icon
          v-else-if="
            item.games_data_gamer_contact === 'ja' || item.isContacted === '1'
          "
          large
          color="amber"
          >mdi-checkbox-blank-circle</v-icon
        >
        <v-icon v-else large color="red">mdi-checkbox-blank-circle</v-icon>
      </template>

      <template v-slot:[`item.contractPromotionText`]="{ item }">
        <span v-if="item.promoPrice"
          >{{ item.promoTitle }}<span v-if="item.promoTitle">:</span>
          {{ item.promoPriceGross }} {{ item.currencyCode }}</span
        >
      </template>

      <template v-slot:[`item.priceGross`]="{ item }">
        {{ item.priceGross }}
        <span v-if="item.currencyCode">{{ item.currencyCode }}</span>
      </template>

      <template v-slot:[`item.price`]="{ item }">
        {{ item.price }}
        <span v-if="item.currencyCode">{{ item.currencyCode }}</span>
      </template>

      <template v-slot:[`item.billingPeriod`]="{ item }">
        {{ $t(item.billingPeriod) }}
      </template>

      <template v-slot:[`item.updatedate`]="{ item }">
        {{ timestampToDate(item.updatedate) }} {{ $t("oclock") }} {{ $t("by") }}
        <span v-if="item.admin_name">{{ item.admin_name }}</span>
        <span v-else>FACEFORCE</span>
      </template>

      <template v-slot:[`item.planState`]="{ item }">
        <div v-if="item.activationdate !== '' || item.isTemplate === '1'">
          <!-- plan active -->

          <v-chip
            v-if="item.yearplanMonthPercentage === '100,0'"
            color="green"
            dark
            small
            class="mt-2"
          >
            <v-icon left small>mdi-check-circle</v-icon>
            {{
              $t("plannedInfo", {
                planned: item.yearplanMonthPlanned,
                active: item.yearplanMonthActive,
              })
            }}
            ({{ item.yearplanMonthPercentage }} %)
          </v-chip>

          <v-chip v-else color="red" dark small class="mt-2">
            <v-icon left small>mdi-information</v-icon>
            {{
              $t("plannedInfo", {
                planned: item.yearplanMonthPlanned,
                active: item.yearplanMonthActive,
              })
            }}
            ({{ item.yearplanMonthPercentage }} %)
          </v-chip>

          <span v-if="item.yearplanVideoPercentage === '100,0'"
            ><br />
            <v-chip color="green" dark small class="mt-1 mb-2">
              <v-icon left small>mdi-check-circle</v-icon>
              {{
                $t("plannedInfoVideos", {
                  planned: item.yearplanVideoPlanned,
                  active: item.yearplanVideoActive,
                })
              }}
              ({{ item.yearplanVideoPercentage }} %)
            </v-chip>
          </span>

          <span v-else
            ><br />
            <v-chip color="red" dark small class="mt-1 mb-2">
              <v-icon left small>mdi-information</v-icon>
              {{
                $t("plannedInfoVideos", {
                  planned: item.yearplanVideoPlanned,
                  active: item.yearplanVideoActive,
                })
              }}
              ({{ item.yearplanVideoPercentage }} %)
            </v-chip>
          </span>
        </div>

        <!-- plan inactive -->
        <div v-else v-html="$t('inactivePlanMessage')"></div>
      </template>

      <template v-slot:[`item.buyerFullname`]="{ item }">
        <span v-if="item.companyName"
          ><span class="font-weight-bold">{{ item.companyName }}</span> ({{
            item.firstName
          }}
          {{ item.lastName }})</span
        >
        <span v-else class="font-weight-bold"
          >{{ item.firstName }} {{ item.lastName }}</span
        >
      </template>

      <template v-slot:[`item.userFullname`]="{ item }">
        <span class="font-weight-bold"
          >{{ item.person_firstname }} {{ item.person_lastname }}</span
        >
      </template>

      <template v-slot:[`item.adminFullname`]="{ item }">
        <span class="font-weight-bold"
          >{{ item.firstname }} {{ item.lastname }}</span
        >
      </template>

      <template v-slot:[`item.contactedIcon`]="{ item }">
        <v-btn
          v-if="
            item.games_data_gamer_contact === 'ja' || item.isContacted === '1'
          "
          elevation="0"
          dark
          fab
          x-small
          color="amber"
          ><v-icon dark>mdi-phone-in-talk</v-icon></v-btn
        >
        <v-btn v-else elevation="0" fab dark x-small color="grey lighten-2"
          ><v-icon dark>mdi-phone-in-talk</v-icon></v-btn
        >
      </template>

      <template v-slot:[`item.scheduledIcon`]="{ item }">
        <v-btn
          v-if="
            item.games_data_gamer_scheduled === 'ja' || item.isScheduled === '1'
          "
          elevation="0"
          fab
          dark
          x-small
          color="lime"
          ><v-icon dark>mdi-calendar-check</v-icon></v-btn
        >
        <v-btn v-else elevation="0" fab dark x-small color="grey lighten-2"
          ><v-icon dark>mdi-calendar-check</v-icon></v-btn
        >
      </template>

      <template v-slot:[`item.begunIcon`]="{ item }">
        <v-btn
          v-if="
            item.games_data_gamer_begun === 'ja' || item.hasRedeemed === '1'
          "
          elevation="0"
          fab
          dark
          x-small
          color="light-green"
          ><v-icon dark>mdi-check</v-icon></v-btn
        >
        <v-btn v-else elevation="0" fab dark x-small color="grey lighten-2"
          ><v-icon dark>mdi-check</v-icon></v-btn
        >
      </template>

      <template v-slot:[`item.lostIcon`]="{ item }">
        <v-btn
          v-if="item.games_data_gamer_lost === 'ja' || item.isLost === '1'"
          elevation="0"
          fab
          dark
          x-small
          color="grey darken-3"
          ><v-icon dark>mdi-emoticon-cry-outline</v-icon></v-btn
        >
        <v-btn v-else elevation="0" fab dark x-small color="grey lighten-2"
          ><v-icon dark>mdi-emoticon-cry-outline</v-icon></v-btn
        >
      </template>

      <template v-slot:[`item.summary`]="{ item }">
        <span v-html="item.summary"></span>
      </template>

      <template v-slot:[`item.gender`]="{ item }">
        <span v-if="item.gender === 'male'">
          {{ $t("mr") }}
        </span>

        <span v-else-if="item.gender === 'female'">
          {{ $t("mrs") }}
        </span>

        <span v-else-if="item.gender === 'diverse' || item.gender === 'divers'">
          {{ $t("Diverse") }}
        </span>

        <span v-else> - </span>
      </template>

      <template v-slot:[`item.person_sex`]="{ item }">
        <span v-if="item.person_sex === 'male'">
          {{ $t("mr") }}
        </span>

        <span v-else-if="item.person_sex === 'female'">
          {{ $t("mrs") }}
        </span>

        <span v-else-if="item.person_sex === 'diverse'">
          {{ $t("Diverse") }}
        </span>

        <span v-else> - </span>
      </template>

      <!-- payProcessStatus -->
      <template v-slot:[`item.payProcessStatus`]="{ item }">
        <v-chip
          v-if="
            item.order.included.payments.status === 'completed' &&
            item.order.isTerminated !== '1'
          "
          color="green"
          class="white--text"
        >
          {{ $t("complete") }}
        </v-chip>

        <v-chip
          v-else-if="item.order.isTerminated === '1'"
          color="black"
          class="white--text"
        >
          {{ $t("Terminated") }}
        </v-chip>

        <v-chip v-else color="light-green" class="white--text">
          {{ $t("noPayment") }}
        </v-chip>
      </template>

      <template v-slot:[`item.campaigns[0].type`]="{ item }">
        <span v-if="item.campaigns[0].type === 'manual'">
          {{ $t("manualType") }}
        </span>

        <span v-else>
          {{ $t("regularType") }}
        </span>
      </template>

      <template v-slot:[`item.campaignTitle`]="{ item }">
        <span v-if="item.version === 1">
          {{ item.campaigns[0].game_title }}
        </span>

        <span v-else-if="item.version === 2">
          {{ item.campaignTitle }}
        </span>
      </template>

      <template v-slot:[`item.pay.items`]="{ item }">
        <ul>
          <li v-for="(prod, index) in item.pay.items" :key="index">
            {{ prod.title }}
          </li>
        </ul>
      </template>

      <template v-slot:[`item.campaignType`]="{ item }">
        <span v-if="item.version === 2">
          <v-chip
            v-if="item.hasMitfitPay === '1' || item.hasMitfitPay === 1"
            color="teal"
            class="white--text"
          >
            PAY
          </v-chip>

          <v-chip
            v-else-if="
              item.hasMitfitMember === '1' || item.hasMitfitMember === 1
            "
            color="teal"
            class="white--text"
          >
            MEMBER
          </v-chip>

          <v-chip
            v-else-if="item.hasMitfitShops === '1' || item.hasMitfitShops === 1"
            color="teal"
            class="white--text"
          >
            SHOPS
          </v-chip>
        </span>
      </template>

      <template v-slot:[`item.provider`]="{ item }">
        <span v-if="item.version === 1">
          <v-chip v-if="item.campaigns[0].type === 'manual'" color="yellow">
            {{ $t("manualType") }}
          </v-chip>

          <v-chip v-else color="primary">
            {{ $t("regularType") }}
          </v-chip>
        </span>

        <span v-else-if="item.version === 2">
          <!-- <v-chip
            v-if="
              item.campaign.hasMitfitPay === '1' ||
              item.campaign.hasMitfitPay === 1
            "
            color="teal"
            class="white--text"
          >
            PAY
          </v-chip> -->

          <v-chip
            v-if="
              item.campaign.hasMitfitMember === '1' ||
              item.campaign.hasMitfitMember === 1
            "
            color="teal"
            class="white--text"
          >
            MEMBER
          </v-chip>

          <v-chip
            v-else-if="
              item.campaign.hasMitfitShop === '1' ||
              item.campaign.hasMitfitShop === 1
            "
            color="cyan"
            class="white--text"
          >
            SHOP
          </v-chip>

          <v-chip v-else-if="item.provider === 'facebook'" color="blue">
            Facebook
          </v-chip>

          <v-chip
            v-else-if="item.provider === 'mitfitCampaign'"
            color="primary"
          >
            {{ $t("regularType") }}
          </v-chip>

          <v-chip v-else-if="item.provider === 'mitfitManual'" color="yellow">
            {{ $t("manualType") }}
          </v-chip>

          <v-chip
            v-else-if="item.provider === 'gkmbWebsite'"
            color="cyan"
            class="white--text"
          >
            GKMB
          </v-chip>
        </span>
      </template>

      <!-- 1/0 = Ja(Nein) -->

      <template v-slot:[`item.isOfferOnly`]="{ item }">
        <span v-if="item.isOfferOnly === '0'">{{ $t("no") }}</span>
        <span v-else>{{ $t("yes") }}</span>
      </template>

      <template v-slot:[`item.isActive`]="{ item }">
        <span v-if="item.isActive === '0'">{{ $t("no") }}</span>
        <span v-else>{{ $t("yes") }}</span>
      </template>

      <!-- Adding clickable elements -->

      <template v-slot:[`item.actions`]="{ item, index }">
        <span v-if="item.isDeleted !== '1'">
          <v-item-group class="d-flex flex-row">
            <div v-if="edit">
              <template
                v-if="
                  item.item &&
                  item.item.vimeo_status &&
                  item.item.vimeo_status !== 'FINISHED'
                "
              >
                {{ $t(item.item.vimeo_status) }}
              </template>

              <template v-else>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      color="primary"
                      @click="editItem(item, index)"
                      v-bind="attrs"
                      v-on="on"
                      class="mx-1"
                      elevation="0"
                    >
                      <v-icon dark>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Bearbeiten</span>
                </v-tooltip>
              </template>
            </div>

            <div v-if="watch">
              <div v-if="item.hasOwnProperty('activationdate')">
                <div
                  v-if="item.activationdate === '' && item.isTemplate !== '1'"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        dark
                        x-small
                        color="pink"
                        @click="activateItem(item, index)"
                        v-bind="attrs"
                        v-on="on"
                        class="mx-1"
                        elevation="0"
                      >
                        <v-icon dark>mdi-lightning-bolt</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("Activate") }}</span>
                  </v-tooltip>
                </div>

                <div v-else>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        dark
                        x-small
                        color="blue"
                        @click="watchItem(item, index)"
                        v-bind="attrs"
                        v-on="on"
                        class="mx-1"
                        elevation="0"
                      >
                        <v-icon dark>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("Watch") }}</span>
                  </v-tooltip>
                </div>
              </div>

              <div v-else>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      color="blue"
                      @click="watchItem(item, index)"
                      v-bind="attrs"
                      v-on="on"
                      class="mx-1"
                      elevation="0"
                    >
                      <v-icon dark>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("Watch") }}</span>
                </v-tooltip>
              </div>
            </div>

            <div v-if="copy">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    dark
                    x-small
                    color="orange"
                    @click="copyItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    class="mx-1"
                    elevation="0"
                  >
                    <v-icon dark>mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("Duplicate") }}</span>
              </v-tooltip>
            </div>

            <div v-if="leads">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    dark
                    x-small
                    color="blue"
                    @click="leadsItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    class="mx-1"
                    elevation="0"
                  >
                    <v-icon dark>mdi-account-details</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("showLeads") }}</span>
              </v-tooltip>
            </div>

            <div v-if="preview">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    dark
                    x-small
                    color="blue"
                    @click="previewItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    class="mx-1"
                    elevation="0"
                  >
                    <v-icon dark>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("showLeads") }}</span>
              </v-tooltip>
            </div>

            <div v-if="stats">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    dark
                    x-small
                    color="indigo"
                    @click="statsItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    class="mx-1"
                    elevation="0"
                  >
                    <v-icon dark>mdi-chart-pie</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("statistics") }}</span>
              </v-tooltip>
            </div>

            <div v-if="exporting">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    dark
                    x-small
                    color="deep-purple lighten-1"
                    @click="exportItem(item, index)"
                    v-bind="attrs"
                    v-on="on"
                    class="mx-1"
                    elevation="0"
                  >
                    <v-icon dark>mdi-file-export</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("Export") }}</span>
              </v-tooltip>
            </div>

            <div v-if="passwordProtection">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    dark
                    x-small
                    color="grey darken-1"
                    @click="passwordProtectItem(item, index)"
                    v-bind="attrs"
                    v-on="on"
                    class="mx-1"
                    elevation="0"
                  >
                    <v-icon dark>mdi-lock</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("passwordProtection") }}</span>
              </v-tooltip>
            </div>

            <div v-if="trash">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    dark
                    x-small
                    color="red"
                    @click="deleteItem(item, index)"
                    v-bind="attrs"
                    v-on="on"
                    class="mx-1"
                    elevation="0"
                  >
                    <v-icon dark>mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("Delete") }}</span>
              </v-tooltip>
            </div>
          </v-item-group>
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import store from "@/store"; // needed for the token
export default {
  data() {
    return {
      searchProp: this.search,
      toggle_exclusive: undefined,
      currentTimestamp: Math.floor(Date.now() / 1000),
      generatingDocument: false,
    };
  },

  methods: {
    row_classes(item) {
      if (
        item.isDeleted === "1" || item.pay
          ? item.pay.isTerminated === "1"
          : 1 !== 1
      ) {
        return "isDeleted"; //can also return multiple classes e.g ["orange","disabled"]
      }
    },

    nextYear(startYear) {
      return parseInt(startYear) + 1;
    },

    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },

    formatDate(date) {
      date = date.replace(/-/g, "/"); // Safari Issue. https://www.evernote.com/shard/s74/nl/14459636/a6d4cd8e-312e-452b-9393-0a7057285e6b?title=Safari%20Date%20Issue
      const dt = new Date(date);
      const d = this.addZero(dt.getDate()); //Month from 0 to 11
      const m = this.addZero(dt.getMonth() + 1); //Month from 0 to 11
      const y = dt.getFullYear();
      const h = this.addZero(dt.getHours());
      const i = this.addZero(dt.getMinutes());
      //const s = this.addZero(dt.getSeconds());
      return (
        d + "." + m + "." + y + " " + h + ":" + i + " " + this.$t("oClock")
      );
    },

    zeroPad(num, places) {
      var zero = places - num.toString().length + 1;
      return Array(+(zero > 0 && zero)).join("0") + num;
    },

    timestampToDate(timestamp) {
      // Converts a timestamp to Date
      timestamp = parseInt(timestamp * 1000);
      var currentDate = new Date(timestamp);
      var date = this.zeroPad(currentDate.getDate(), 2);
      var month = this.zeroPad(currentDate.getMonth() + 1, 2);
      var year = currentDate.getFullYear();
      var hours = this.zeroPad(currentDate.getHours(), 2);
      var minutes = this.zeroPad(currentDate.getMinutes(), 2);
      var seconds = this.zeroPad(currentDate.getSeconds(), 2);
      var formattedTime =
        date +
        "." +
        month +
        "." +
        year +
        ", " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
      return formattedTime;
    },

    /* Emitting data to parent component
      Die Daten werden in der emittedAction() Method der Elternkomponente verarbeitet
      */

    editItem(item, index) {
      this.$emit("tableEvent", {
        action: "editItem",
        itemId: item.id,
        itemIndex: index,
        itemData: item, // emit the complete data of that item
      }); // emit to parent-component
    },

    watchItem(item, index) {
      this.$emit("tableEvent", {
        action: "watchItem",
        itemId: item.id,
        itemIndex: index,
        itemData: item, // emit the complete data of that item
      }); // emit to parent-component
    },

    activateItem(item, index) {
      this.$emit("tableEvent", {
        action: "activateItem",
        itemId: item.id,
        itemIndex: index,
        itemData: item, // emit the complete data of that item
      }); // emit to parent-component
    },

    safeItem(item) {
      this.$emit("tableEvent", {
        action: "safeItem",
        itemId: item.id,
      }); // emit to parent-component
    },

    deleteItem(item, index) {
      this.$emit("tableEvent", {
        action: "deleteItem",
        itemId: item.id,
        itemIndex: index,
        item: item,
      }); // emit to parent-component
    },
    copyItem(item) {
      this.$emit("tableEvent", {
        action: "copyItem",
        itemId: item.id,
      }); // emit to parent-component
    },
    duplicateItem(item) {
      this.$emit("tableEvent", {
        action: "duplicateItem",
        itemId: item.id,
      }); // emit to parent-component
    },
    abortItem(item) {
      this.$emit("tableEvent", {
        action: "abortItem",
        itemId: item.id,
      }); // emit to parent-component
    },
    leadsItem(item) {
      this.$emit("tableEvent", {
        action: "leadsItem",
        itemId: item.id,
      }); // emit to parent-component
    },
    previewItem(item) {
      this.$emit("tableEvent", {
        action: "previewItem",
        itemId: item.id,
      }); // emit to parent-component
    },
    statsItem(item) {
      this.$emit("tableEvent", {
        action: "statsItem",
        itemId: item.id,
      }); // emit to parent-component
    },

    exportItem(item, index) {
      this.$emit("tableEvent", {
        action: "exportItem",
        itemId: item.id,
        itemIndex: index,
      }); // emit to parent-component
    },

    passwordProtectItem(item, index) {
      this.$emit("tableEvent", {
        action: "passwordProtection",
        itemId: item.id,
        itemIndex: index,
        itemData: item, // emit the complete data of that item
      }); // emit to parent-component
    },

    downloadDocument(getRoute) {
      // use the getRoute to download the document via fetch-API GET and window.open()
      this.generatingDocument = true;
      fetch(this.$manageApiUrl + getRoute, {
        method: "GET",
        headers: {
          Token: store.getters["auth/manageToken"],
        },
      })
        .then((response) => {
          // response is a file (pdf)
          return response.blob();
        })
        .then((data) => {
          this.generatingDocument = false;
          const file = new Blob([data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        });
    },
  },

  name: "DataTable",
  props: [
    "headers",
    "items",
    "search",
    "edit",
    "watch",
    "trash",
    "copy",
    "leads",
    "preview",
    "stats",
    "exporting",
    "passwordProtection",
    "preloader",
  ],
};
</script>

<style>
.mitfit-table thead {
  background-color: #282d35 !important;
  color: #ffffff !important;
}

.mitfit-table thead th {
  color: #ffffff !important;
}

.mitfit-table td {
  padding: 1em !important;
}

.isDeleted {
  opacity: 0.2;
}
</style>
